import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import Video from './video';

import cls from './customization.module.scss';

const Customization = () => {
  const { t } = useTranslation();
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(
      () => {
        setHidden(!hidden);
      },
      hidden ? 5500 : 13000
    );

    return () => clearInterval(intervalId);
  }, [hidden]);

  return (
    <div className={cls.wrapper}>
      <div className={cls.left}>
        <h1>{t('customization.title')}</h1>
        <div className={cx(cls.card, !hidden && cls.hidden)} onClick={() => setHidden(false)}>
          <p>{t('customization.card1.title')}</p>
          <div className={cls.line}>
            <div className={cx(cls.line2, !hidden && cls.anim)} />
          </div>
          <h1 className={cx(cls.description, !hidden && cls.hid)}>{t('customization.card1.description')}</h1>
        </div>
        <div className={cx(cls.card2, hidden && cls.hidden)} onClick={() => setHidden(true)}>
          <p>{t('customization.card2.title')}</p>
          <div className={cls.line}>
            <div className={cx(cls.line2, hidden && cls.anim)} />
          </div>
          <h1 className={cx(cls.description, hidden && cls.hid)}>{t('customization.card2.description')}</h1>
        </div>
        {/* <Link to='footer' duration={500} className={cls.btn}>
          {t("customization.button")}
        </Link> */}
      </div>
      <div className={cls.right}>
        <div className={cls.card}>
          <Video key={Number(hidden)} hidden={hidden} />
        </div>
      </div>
    </div>
  );
};

export default Customization;
