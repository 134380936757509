import { useTranslation } from 'react-i18next';

import instagram from 'assets/images/Group 417 (1).svg';
import dress from 'assets/images/image 18.svg';
// @ts-ignore
import bacVideo from 'assets/video/A_min.mp4';

import cls from './content.module.scss';

const Content = () => {
  const { t } = useTranslation();

  return (
    <div className={cls.wrapper}>
      <div className={cls.left}>
        <h1>{t('content.title')}</h1>
        <div className={cls.card}>
          <p>{t('content.content1')}</p>
          <div className={cls.wrap}>
            <img src={dress} className={cls.instagram} alt="img not found" />
          </div>
        </div>
        {/* <img className={cls.img} src={bacImg} alt='img not found' /> */}
        <div className={cls.card2}>
          <p>{t('content.content2')}</p>
          <div className={cls.wrap}>
            <img src={instagram} className={cls.instagram} alt="img not found" />
          </div>
        </div>
      </div>
      <div className={cls.right}>
        <div
          className={cls.card}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `
                  <video loop autoPlay playsInline muted>
                    <source src=${bacVideo} type='video/mp4' />
                 </video>,
              `
          }}
        />
      </div>
    </div>
  );
};

export default Content;
