import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import Cookie from 'components/cookie/cookie';

// @ts-ignore
import desktop from 'assets/video/desktop.mp4';
// @ts-ignore
import mobil from 'assets/video/mobil.mp4';

import cls from './hero.module.scss';

const Hero = () => {
  const { t } = useTranslation();
  const [state, setState] = useState();
  const ref = useRef(null);
  const [anim, setAnim] = useState(false);
  const [cookie, setCookie] = useState(true);

  const handleMouse = (e: { pageX: number; pageY: number }) => {
    // @ts-ignore
    state.forEach((element: { getAttribute: (arg0: string) => any; style: { transform: string } }) => {
      const speed = element.getAttribute('data-speed');
      const x = (window.innerWidth - e.pageX * speed) / 50;
      const y = (window.innerHeight - e.pageY * speed) / 50;

      element.style.transform = `translateX(${x}px) translateY(${y}px`;
    });
  };

  useEffect(() => {
    setAnim(true);
    // @ts-ignore
    setState(Array.from(ref.current.querySelectorAll('div')));
  }, []);

  return (
    <div className={cls.wrapper}>
      <div className={cls.video}>
        <div
          className={cls.desktop}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `
          <video loop autoPlay playsInline muted >
          <source src=${desktop} type="video/mp4" className=${cls.desktop} />
        </video>
      `
          }}
        />
        <div
          className={cls.mobil}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `
          <video loop autoPlay playsInline muted >
          <source src=${mobil} type="video/mp4" className=${cls.mobil} />
        </video>
      `
          }}
        />
      </div>
      <div ref={ref} className={cx(cls.card, anim && cls.anim)} onMouseMove={handleMouse}>
        <div data-speed="4" className={cls.text}>
          {t('hero.title')}
        </div>
        <div data-speed="4" className={cls.btn}>
          {t('hero.button')}
        </div>
      </div>
      {cookie && (
        <div className={cx(cls.cookie, anim && cls.anim)}>
          <Cookie onCookie={() => setCookie(!cookie)} />
        </div>
      )}
    </div>
  );
};

export default Hero;
