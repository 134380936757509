import React, { FC, useEffect, useState } from 'react';
import axios from 'axios';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-scroll';

import logo from 'assets/images/buduar.svg';
import logo2 from 'assets/images/Logo-New.png';
import hamburger from 'assets/images/white.svg';

import cls from './navbar.module.scss';

interface NavbarProps {
  open: boolean;
  setOpen: any;
}

const Navbar: FC<NavbarProps> = ({ open, setOpen }) => {
  const [anim, setAnim] = useState(false);
  const options = [{ lang: 'EN' }, { lang: 'RU' }];
  const { t, i18n } = useTranslation();
  const [languageOption, setLanguageOption] = useState('EN');

  useEffect(() => {
    axios
      .get('https://geolocation-db.com/json/')
      .then(({ data }) => {
        if (data && data.country_name !== 'Not found') {
          data.country_code === 'RU' ? changeLanguage('RU') : changeLanguage('EN');
        }
      })
      .catch(() => {
        changeLanguage('EN');
      });
    setAnim(true);
  }, []);

  const changeLanguage = (option = 'RU') => {
    setLanguageOption(option);
    i18n.changeLanguage(option);
  };

  return (
    <div className={cx(cls.wrapper, open && cls.open)}>
      <div className={cx(cls.row, anim && cls.anim)}>
        <div className={cls.logo}>
          <div className={cls.images}>
            <img src={logo2} alt="img not found" />
            <img src={logo} alt="img not found" />
          </div>
          <div className={cls.hamburger}>
            <button type="button" className={cls.btn} onClick={() => setOpen(!open)}>
              <img src={hamburger} alt="img not found" />
            </button>
            {/* <Segmented
              value={languageOption}
              options={options}
              onChange={(e) => changeLanguage(e)}
            /> */}
            {/* <div className={cls.desktop}>
              <Link to='footer' duration={500} className={cls.btn}>
                {t("navbar.button")}
              </Link>
              <div
                className={cx(cls.cards, languageOption === "RU" && cls.act)}>
                {options.map((item) => (
                  <div
                    key={item.lang}
                    className={cx(
                      cls.card,
                      languageOption === item.lang && cls.active
                    )}
                    onClick={() => changeLanguage(item.lang)}>
                    <span>{item.lang}</span>
                  </div>
                ))}
              </div>
            </div> */}
            {/* <img src={open ? img1 : img2} alt='img not found' /> */}
          </div>
        </div>

        <div className={cx(cls.nav, open && cls.active)}>
          {/* <ul className={cls.ul}>
            <li className={cls.li}>
              <a href="#about">about</a>
            </li>
            <li className={cls.li}>
              <a href="#price">price</a>
            </li>
            <li className={cls.li}>
              <a href="#product">products</a>
            </li>
          </ul> */}
          {/* <div className={cls.btn}>download</div> */}
          <div className={cls.desktop}>
            <Link to="footer" duration={500} className={cls.btn}>
              {t('navbar.button')}
            </Link>
            <div className={cx(cls.cards, languageOption === 'RU' && cls.act)}>
              {options.map(item => (
                <div key={item.lang} className={cx(cls.card, languageOption === item.lang && cls.active)} onClick={() => changeLanguage(item.lang)}>
                  <span>{item.lang}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
