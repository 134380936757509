import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Landing, PageNotFound, Product, Terms } from 'pages';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<Landing />} />
        <Route path="/" element={<Landing />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/product" element={<Product />} />
        <Route path="/404" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
