import { Suspense, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Center, ContactShadows, Environment, Html, OrbitControls, useTexture } from '@react-three/drei';
import { Canvas, useLoader } from '@react-three/fiber';
import cx from 'classnames';
import Lottie from 'lottie-react-component';
import { map } from 'modern-async';
import useProduct from 'modules/product/hooks/use-product';
import { IEntity } from 'modules/product/types';
import { NewFooter } from 'pages/landing/components';
import { useInView } from 'react-intersection-observer';
import * as THREE from 'three';
import { Mesh, Texture } from 'three';
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter.js';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';

import { PageLoader } from 'components';
import Navbar from 'components/navbar/navbar';

import poster from 'assets/images/Logo-New.png';
import LoaderJson from 'assets/lottie/loader.json';

import cls from './product.module.scss';

const Product = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const itemID = params.get('id');
  const { isLoading, item } = useProduct(itemID!);
  const meshRef = useRef();
  const [textures, setTextures] = useState<any>({});
  const [object, setObject] = useState<string | null>(null);
  const [model, setModel] = useState();

  const [active, setActive] = useState(false);

  useEffect(() => {
    typeof window !== 'undefined' && active ? document.body.classList.toggle('open-modal') : document.body.classList.remove('open-modal');

    !isLoading && item.name && setObject(item.variants[0].objectUrl);
  }, [active, navigate, itemID, isLoading, item.variants, item.name]);

  const { inView } = useInView({
    threshold: 0.95,
    rootMargin: ''
  });

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const interval = setInterval(() => {
      downloadGLB();
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [model]);

  function downloadGLB() {
    const exporter = new GLTFExporter();

    if (meshRef.current) {
      exporter.parse(
        meshRef.current,
        exported => {
          // @ts-ignore
          setModel(URL.createObjectURL(new Blob([exported], { type: 'application/octet-stream' })));
        },
        error => {
          // eslint-disable-next-line no-console
          console.log('error', error);
        },
        { binary: true }
      );
    }
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useMemo(() => {
    const textures: any = {};

    const renameTextures: { [key: string]: string } = {
      metallicmap: 'metalnessMap',
      roughnessmap: 'roughnessMap',
      normalmap: 'normalMap',
      albedomap: 'map',
      displacementmap: 'displacementMap'
    };

    map(item.materials, (material: IEntity.Material, index: number) => {
      Object.keys(material)
        .filter(x => x.includes('map'))
        // eslint-disable-next-line array-callback-return
        .map((x: string) => {
          /* @ts-ignore */
          const texture = item.materials[index][x];

          if (texture !== '') {
            textures[renameTextures[x]] = texture;
          }
        });
    });
    setTextures(textures);
    // eslint-disable-next-line no-console
    console.log('textures in material preview =', textures);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.materials]);

  function Geometry() {
    const ref = useRef();

    // @ts-ignore
    const garmentObj = useLoader(OBJLoader, object);

    // console.log('garmentObj', garmentObj);
    const garmentGeometry = useMemo(() => {
      let g;
      /* @ts-ignore */

      garmentObj.traverse(c => {
        if (c.type === 'Mesh') {
          const _c = c as Mesh;

          g = _c.geometry;
        }
        // console.log('_c !== Mesh', c);
      });
      return g;
    }, [garmentObj]);

    const textureMaps = useTexture(textures, (textures: Texture | Texture[]) => {
      // eslint-disable-next-line array-callback-return
      item.materials.map((material: IEntity.Material) => {
        const { scale, rotation, color } = material;

        /* @ts-ignore */
        // eslint-disable-next-line array-callback-return
        textures.map((texture: Texture) => {
          texture.needsUpdate = true;
          texture.wrapS = THREE.RepeatWrapping;
          texture.wrapT = THREE.RepeatWrapping;
          texture.repeat.set(scale * 1, scale * 1);
          texture.rotation = rotation * (Math.PI / 180);
        });
      });
    });
    const materialColor = item.materials.find(element => element.type === 'main')?.color;
    const props = {
      ...(Object.keys(textures).length ? textureMaps : null)
    };

    return (
      /* @ts-ignore */
      <Center getObjectsByProperty="obj" top>
        {/* @ts-ignore */}
        <group ref={ref} dispose={null}>
          {/* @ts-ignore */}
          <mesh ref={meshRef} receiveShadow castShadow geometry={garmentGeometry}>
            <meshPhysicalMaterial color={textures.map ? 'transparent' : materialColor} side={THREE.DoubleSide} needsUpdate={true} transparent={true} metalness={0.5} {...props} />
          </mesh>
        </group>
      </Center>
    );
  }

  if (isLoading) return <PageLoader />;

  return (
    <div className={cls.wrapper}>
      <div className={cx(cls.nav, !inView && cls.open)}>
        <Navbar open={active} setOpen={() => setActive(!active)} />
      </div>
      <div className={cls.container}>
        <div className={cls.left}>
          <div className={cls['card-header']}>
            <p className={cls.text}>
              {item.category} / {item.subcategory}
            </p>
            <h1 className={cls.title}>{item.name}</h1>
            <div className={cls.card}>100% cotton</div>
            {item.description ? (
              <>
                <h2 className={cls.description_title}>Description</h2>
                <p className={cls.description}>This is my favorite item is my favorite item is my favorite item is my favorite item</p>
              </>
            ) : null}
          </div>
        </div>
        <div className={cls.right}>
          {model ? (
            /* @ts-ignore */
            <model-viewer xr-environment class={cls.viewer} shadow-intensity="1" camera-controls touch-action="pan-y" src={model} ar poster={poster} />
          ) : (
            <Lottie style={{ width: 300, height: 300 }} animationData={LoaderJson} loop={true} />
          )}

          <p className={cls.id}>buduar/look_for_v_day</p>
        </div>
        <Canvas style={{ opacity: 0 }} shadows camera={{ position: [0, 1, 3] }}>
          <ambientLight intensity={0.7} />
          <spotLight intensity={0.5} angle={0.1} penumbra={1} position={[10, 15, 10]} castShadow />
          <Suspense
            fallback={
              <Html center>
                <p style={{ width: '100%' }}>LOADING 3D model</p>
              </Html>
            }
          >
            <Geometry />
            <Environment preset="city" blur={1} background />
            {/* @ts-ignore */}
            <ContactShadows getObjectByProperty="obj" position={[0, -0.8, 0]} opacity={0.25} scale={10} blur={1.5} far={0.8} />
          </Suspense>
          <OrbitControls enableZoom enablePan enableRotate />
        </Canvas>
      </div>
      <NewFooter />
    </div>
  );
};

export default Product;
