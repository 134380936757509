import React, { FC } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import cls from './cookie.module.scss';

interface CookieProps {
  onCookie?: any;
  anim?: any;
}

const Cookie: FC<CookieProps> = ({ onCookie, anim }) => {
  const { t } = useTranslation();

  return (
    <div className={cls.wrapper}>
      <div className={cx(cls.container, anim && cls.anim)}>
        <div className={cls.description}>
          {t('cookie.first-paragraph')}
          &nbsp;
          <a href="https://buduar.space" target="_blank" rel="noreferrer">
            buduar.space
          </a>
          &nbsp; {t('cookie.second-paragraph')} &nbsp;
          <a href="https://buduar.space/terms" target="_blank" rel="noreferrer">
            {t('cookie.third-paragraph')}
          </a>
        </div>
        <button type="button" className={cls.btn} onClick={onCookie}>
          OK
        </button>
      </div>
    </div>
  );
};

export default Cookie;
