import React from 'react';
import { useTranslation } from 'react-i18next';

import apple from 'assets/images/download/apple.svg';
import google from 'assets/images/download/google.svg';

import cls from './download.module.scss';

export default function Download() {
  const { t } = useTranslation();

  return (
    <div className={cls.wrapper}>
      <div className={cls.container}>
        <h1 className={cls.title}>{t('download.title')}</h1>
        <div className={cls.cards}>
          <a href="#" target="_blank" rel="noopener noreferrer" className={cls.link}>
            <img src={apple} alt="img not found" />
          </a>
          <a href="#" target="_blank" rel="noopener noreferrer" className={cls.link}>
            <img src={google} alt="img not found" />
          </a>
        </div>
      </div>
    </div>
  );
}
