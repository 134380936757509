import { FC } from 'react';

// @ts-ignore
import outfitVideo from 'assets/video/B_9-16_interface_min.mp4';
// @ts-ignore
import sceneVideo from 'assets/video/V_9-16_interface_min.mp4';

import cls from './customization.module.scss';

interface VideoProps {
  hidden?: boolean;
}

const Video: FC<VideoProps> = ({ hidden }) => (
  <div className={cls.cards}>
    <div
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: `
              <video loop playsInline muted autoPlay className={cls.video}>
              <source src=${hidden ? sceneVideo : outfitVideo} type="video/mp4" />
            </video>,
      `
      }}
    />
  </div>
);

export default Video;
