const EN = {
  translation: {
    languages: {
      ENG: 'EN',
      RU: 'RU',
    },
    navbar: {
      button: 'Download app',
    },
    hero: {
      title: 'Digitizing your brand',
      button: 'less in 5 minutes',
    },
    content: {
      title: 'Digital fashion content should be accessible',
      content1:
        'Convert your inventory to digital format using only your phone',
      content2:
        'Increase sales by promoting the 3D content of your clothing store in your social networks or marketplaces',
    },
    realistic: {
      title: '99.6% Realistic',
      description: '3D or virtual - doesn’t mean it’s not realistic.',
      content1:
        'Convert your inventory to digital format using only your phone',
      content2:
        'Increase sales by promoting the 3D content of your clothing store in your social networks or marketplaces',
    },
    customization: {
      title: 'Customization is key',
      card1: {
        title: 'Outfit',
        description:
          'Change poses, customize clothes to fit your clothing store, creating realistic 3D looks',
      },
      card2: {
        title: 'Scene',
        description:
          'Choose the scene, animation and model movement. Create unique content',
      },
      button: 'Join waiting list',
    },
    slider: {
      title: 'See some examples',
      card1: {
        title: 'Static poses',
        description:
          'Description of outfit customization option description of outfit customization option description of outfit customization option',
      },
    },
    footer: {
      title: 'Sign up for the newsletter',
      placeholder: 'example@gmail.com',
      'privacy-policy': 'Privacy policy',
      copyright: '© 2018-2023 Spacer World OU. All rights received',
    },
    mission: {
      title: 'Our mission',
      description: `BuduAR is the world's first innovative solution that will help SMBs compete with giant corporations for audience attention with greater efficiency.
<br/>
<br/>
With the help of our artificial intelligence, you can easily digitize the trading positions of your online store.
`,
    },
    download: {
      title: 'Download an app',
    },
    terms: {
      title: 'Privacy policy',
    },
    cookie: {
      'first-paragraph':
        'Our site uses cookies to improve site performance, efficiency and user-friendliness. Continuing to use the site',
      'second-paragraph': 'you are agreeing',
      'third-paragraph': 'with our privacy policy',
    },
  },
};

export default EN;
