import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';

import * as Api from '../api';
import * as Constants from '../constants';
import * as Mappers from '../mappers';
import * as Types from '../types';

const useProduct = (id: string) => {
  const navigate = useNavigate();
  const initialData = { item: {} } as Types.IQuery.Single;

  const { data = initialData, ...args } = useQuery<Types.IQuery.Single, string, Types.IQuery.Single>(
    [Constants.PRODUCT, 'product'],
    async () => {
      const { data } = await Api.Product(id);

      const item = Mappers.Product(data[0]);

      return { item };
    },
    { enabled: !!id, keepPreviousData: true, retry: false, onError: () => navigate('/404') }
  );

  return { ...data, ...args };
};

export default useProduct;
