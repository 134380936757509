import './bootstrap';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import i18n from './translation/i18n';
import App from './app';

import './assets/style/main.scss';

const root = createRoot(document.getElementById('root') as HTMLElement);

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onSuccess: () => {},
    onError: () => {}
  }),
  mutationCache: new MutationCache({
    onSuccess: () => {},
    onError: () => {}
  }),
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <QueryClientProvider client={queryClient}>
        <>
          <ReactQueryDevtools initialIsOpen={false} />
          <App />
        </>
      </QueryClientProvider>
    </I18nextProvider>
  </React.StrictMode>
);
