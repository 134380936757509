import React from 'react';
import ReactCompareImage from 'react-compare-image';
import { useTranslation } from 'react-i18next';

import left from 'assets/images/Left.png';
import camera from 'assets/images/realistic/camera.svg';
import bac_img from 'assets/images/realistic/Satin_fabric.png';
import star from 'assets/images/realistic/star.svg';
import right from 'assets/images/Right.png';

import cls from './realistic.module.scss';

const Realistic = () => {
  const { t } = useTranslation();

  return (
    <div className={cls.wrapper}>
      <img className={cls.bac_img} src={bac_img} alt="img not found" />
      <div className={cls.container}>
        <h1>{t('realistic.title')}</h1>
        <p>{t('realistic.description')}</p>
        <div className={cls.row}>
          <div className={cls.left}>
            <div className={cls.cards}>
              <ReactCompareImage leftImage={left} rightImage={right} />
            </div>
          </div>
          <div className={cls.right}>
            <div className={cls.card}>
              <img src={camera} alt="img not found" />
              <h1 className={cls.text}>{t('realistic.content1')}</h1>
            </div>
            <div className={cls.card}>
              <img src={star} alt="img not found" />
              <h1 className={cls.text}>{t('realistic.content2')}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Realistic;
