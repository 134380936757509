import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import buduar from 'assets/images/buduar.svg';
import next from 'assets/images/next.png';

import cls from './new-footer.module.scss';

export default function NewFooter() {
  const { t } = useTranslation();
  const [value, setValue] = useState('');

  return (
    <div className={cls.wrapper}>
      <div className={cls.container}>
        <div className={cls.cards}>
          <img src={buduar} alt="img not found" />
          <p>info@buduar.space</p>
        </div>
        <div className={cls.cards}>
          <h1 className={cls.title}>{t('footer.title')}</h1>
          <div className={cls.form} id="footer">
            {/* @ts-ignore */}
            <input type="text" placeholder={t('footer.placeholder')} value={value} onChange={e => setValue(e.target.value)} />
            <button className={cls.btn}>
              <img src={next} alt="img not found" />
            </button>
          </div>
        </div>
      </div>
      <div className={cls.row}>
        <h2>{t('footer.copyright')}</h2>
        <a href="https://buduar.space/terms" target="_blank" rel="noreferrer">
          {t('footer.privacy-policy')}
        </a>
      </div>
    </div>
  );
}
