export enum GenderType {
  f = 'f',
  m = 'm'
}

export const PRODUCT = 'PRODUCT';

export enum MaterialType {
  main = 'main',
  detail = 'detail'
}

export enum TextureType {
  albedomap = 'albedomap',
  normalmap = 'normalmap',
  roughnessmap = 'roughnessmap',
  metallicmap = 'metallicmap'
}

export enum FabricType {
  cotton_gabardine = 'cotton_gabardine',
  cotton_heavy_twill = 'cotton_heavy_twill',
  cotton_stretch_sateen = 'cotton_stretch_sateen',
  cotton_40s_stretch_poplin = 'cotton_40s_stretch_poplin',
  denim_stretch = 'denim_stretch',
  knit_cotton_rayon_jersey = 'knit_cotton_rayon_jersey',
  leather_lambskin = 'leather_lambskin',
  linen = 'linen',
  muslin = 'muslin',
  wool = 'wool'
}
