const RU = {
  translation: {
    languages: {
      ENG: 'АНГ',
      RU: 'РУС',
    },
    navbar: {
      button: 'Скачать',
    },
    hero: {
      title: 'Оцифруй свой бренд одежды',
      button: 'всего за 5 минут',
    },
    content: {
      title: '3D контент может быть доступен каждому',
      content1:
        'Переведи свой интернет магазин одежды в цифровой формат, используя только телефон',
      content2:
        'Увеличивай продажи продвигая 3д контент своего магазина одежды в своих социальных сетях или на торговых площадках',
    },
    realistic: {
      title: 'Максимальная реалистичность',
      description: 'Виртуальное - не значит, нереалистично',
      content1: `Контент без студии,
без фотографа, моделей
и лишних затрат`,
      content2: `Создание цифровой одежды стало быстрым, легким и удобным`,
    },
    customization: {
      title: 'BuduAR - это ключ к твоему успеху',
      card1: {
        title: 'Одежда',
        description:
          'Меняй позы, настраивай одежду под свой магазин одежды, создавая реалистичные 3D луки',
      },
      card2: {
        title: 'Сцена',
        description:
          'Выбирай сцену, анимацию и движение модели. Создавай свой уникальный контент',
      },
      button: 'Присоединиться к бете',
    },
    slider: {
      title: 'Примеры готовых маетриалов',
      card1: {
        title: 'Static poses',
        description:
          'Description of outfit customization option description of outfit customization option description of outfit customization option',
      },
    },
    footer: {
      title: 'Оставайся в курсе новостей',
      placeholder: 'email@mail.ru',
      'privacy-policy': 'Политика конфиденциальности',
      copyright: '© 2018-2023 Spacer World OU. Все права защищены.',
    },
    mission: {
      title: 'Наша миссия',
      description: `BuduAR представляет собой первое в мире инновационное решение, которое поможет конкурировать малому и среднему бизнесу с гигантскими корпорациями за внимание аудитории с большей эффективностью.
<br/>
<br/>
При помощи нашего искусственного интеллекта, вы сможете легко и просто оцифровать торговые позиции своего интернет магазина.
`,
    },
    download: {
      title: 'Скачивай наше приложение',
    },
    terms: {
      title: 'Политика конфиденциальности',
    },
    cookie: {
      'first-paragraph':
        'Наш сайт использует файлы cookie, чтобы улучшить работу сайта, повысить его эффективность и удобство. Продолжая использовать сайт',
      'second-paragraph': 'вы соглашаетесь',
      'third-paragraph': 'с политикой конфиденциальности',
    },
  },
};

export default RU;
