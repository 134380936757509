import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { useInView } from 'react-intersection-observer';

import Cookie from 'components/cookie/cookie';
import Navbar from 'components/navbar/navbar';

import { Content, Customization, Download, Hero, Mission, NewFooter, Realistic } from './components';

import cls from './landing.module.scss';

const Landing = () => {
  const [anim, setAnim] = useState(false);
  const [cookie, setCookie] = useState(true);
  const [active, setActive] = useState(false);
  const { ref, inView } = useInView({
    threshold: 0.95,
    rootMargin: ''
  });

  useEffect(() => {
    setAnim(true);
  }, []);

  useEffect(() => {
    typeof window !== 'undefined' && active ? document.body.classList.toggle('open-modal') : document.body.classList.remove('open-modal');
  }, [active]);

  return (
    <>
      <div className={cls.wrapper}>
        <div className={cx(cls.nav, !inView && cls.open)}>
          <Navbar open={active} setOpen={() => setActive(!active)} />
        </div>
        <div ref={ref}>
          <Hero />
        </div>
        <Content />
        <Realistic />
        <Mission />
        <Customization />
        <Download />
        <NewFooter />
        {cookie && <Cookie anim={anim} onCookie={() => setCookie(!cookie)} />}
      </div>
    </>
  );
};

export default Landing;
