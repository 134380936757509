import { FC } from 'react';

import logo from 'assets/images/Logo-New.png';

import cls from './not-found.module.scss';

const PageNotFound: FC = () => (
  <div className={cls.wrapper}>
    <div className={cls.logo}>
      <img src={logo} alt="logo" />
    </div>
    <div className={cls.text}>
      <h1>The product link is invalid</h1>
      <p>The link you’ve tried to access does not exist or invalid</p>
    </div>
  </div>
);

export default PageNotFound;
