import React, { useState } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';

import Navbar from 'components/navbar/navbar';

import NewFooter from '../landing/components/new-footer/new-footer';

import cls from './terms.module.scss';

export default function Terms() {
  const [active, setActive] = useState(false);
  const { t } = useTranslation();

  const { ref, inView } = useInView({
    threshold: 0.95,
    rootMargin: ''
  });

  return (
    <div className={cls.wrapper}>
      <div className={cx(cls.nav, !inView && cls.open)}>
        <Navbar open={active} setOpen={() => setActive(!active)} />
      </div>
      <div ref={ref} className={cls.container}>
        <h1 className={cls.title}>{t('terms.title')}</h1>
        <div className={cls.description}>
          <h2>Общие положения</h2>
          <p className={cls.col}>
            2.1. Настоящая Политика конфиденциальности персональных данных Акционерного общества «Главный научный инновационный вычислительный центр» (далее – Политика конфиденциальности) разработана
            Акционерным обществом «Главный научный инновационный внедренческий центр» (далее – АО «ГНИВЦ», Администрация Сайта) и применяется при использовании сайтов gnivc.ru, edu.gnivc.ru,
            forum.gnivc.ru и иных интернет-сервисов, которые ссылаются на настоящую Политику конфиденциальности, и через которые АО «ГНИВЦ» собирает персональные данные любых лиц, посещающих указанные
            сайты (далее – Сайт).
          </p>
          <p>
            2.2. Политика конфиденциальности разработана в соответствии с требованиями Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных» (далее – Федеральный закон «О персональных
            данных»), иных федеральных законов, регулирующих вопросы обработки персональных данных, а также принятых во их исполнение подзаконных нормативных правовых актов.
          </p>
          <p>2.3. Использование сервисов Сайта Пользователем Сайта означает его безоговорочное согласие с Политикой конфиденциальности и условиями обработки персональных данных Пользователя Сайта.</p>
          <p>2.4. В случае несогласия с условиями Политики конфиденциальности Пользователь Сайта должен прекратить использование Сайта.</p>
          <p>
            2.5. Политика конфиденциальности определяет основные цели и условия обработки персональных данных Пользователей Сайта и сведения о реализуемых требованиях к защите персональных данных
            Пользователей Сайта.
          </p>
          <p>
            2.6. Политика конфиденциальности применяется только к Сайту. По иным вопросам обработки персональных данных в АО «ГНИВЦ» разработаны и внедрены локальные акты. Администрация Сайта не
            контролирует и не несет ответственность за сайты третьих лиц, на которые Пользователь Сайта может перейти по ссылкам, доступным на Сайте.
          </p>
          <p>
            2.7. Администрация Сайта является оператором только тех персональных данных, которые получает от Пользователей Сайта при использовании Сайта с их согласия, предоставляемого путем
            совершения Пользователем Сайта конклюдентных действий на Сайте.
          </p>
          <p>
            2.8. Администрация Сайта не проверяет достоверность персональных данных, предоставляемых Пользователем Сайта на Сайте. Администрация Сайта исходит из того, что Пользователь Сайта
            предоставляет достоверные и достаточные персональные данные и поддерживает эту информацию в актуальном состоянии. Всю ответственность за последствия предоставления недостоверных или
            недействительных персональных данных несет лично Пользователь Сайта.
          </p>
          <p>
            2.9. Пользователь Сайта подтверждает, что, предоставляя свои персональные данные и давая согласие на их обработку, он действует добровольно, своей волей и в своем интересе, а также
            подтверждает свою дееспособность.
          </p>
          <p>
            2.10. Предоставляя свои персональные данные, Пользователь Сайта соглашается с тем, что предоставленные им персональные данные будут обрабатываться АО «ГНИВЦ» (адрес: 125373, г. Москва,
            Походный проезд, домовладение 3, строение 1, ИНН 7733284010, ОГРН 1167746447461) как с использованием средств автоматизации, так и без использования таких средств.
          </p>
          <p>
            2.11. Персональные данные, разрешённые к обработке в рамках Политики конфиденциальности, не являются специальными или биометрическими, предоставляются Пользователем Сайта путём заполнения
            специальных форм на Сайте бессрочно (вплоть до отзыва Пользователем Сайта своего согласия на обработку персональных данных). В зависимости от заполняемой Пользователем веб-формы, данные
            включают в себя следующую информацию:
            <ul>
              <li>фамилию, имя, отчество;</li>
              <li>дата рождения;</li>
              <li>контактный телефон;</li>
              <li>адрес электронной почты (e-mail);</li>
              <li>название организации (места работы);</li>
              <li>должность.</li>
            </ul>
            Заполняя соответствующие веб-формы и/или отправляя свои персональные данные Администрации Сайта, Пользователь Сайта выражает свое согласие с Политикой конфиденциальности.
          </p>
          <p>
            2.12. Администрация Сайта также принимает меры по защите персональных данных, которые автоматически передаются в процессе посещения страниц Сайта, в том числе из файлов cookie:
            <ul>
              <li>IP адрес, присвоенный компьютеру Пользователя в момент посещения Сайта;</li>
              <li>Полученные данные о сессиях.</li>
            </ul>
          </p>
          <p className={cls.col}>Сеансовые cookie-файлы</p>
          <p className={cls.col}>Сookie-файлы могут быть как «сеансовые», так и «постоянные».</p>
          <p className={cls.col}>
            Администрация Сайта использует сеансовые cookie-файлы, чтобы присвоить уникальный идентификационный номер компьютеру Пользователя Сайта при каждом посещении Сайта, при этом после закрытия
            браузера они удаляются. Такие файлы применяются также для анализа работы Пользователя Сайта с Сайтом (изучается посещение страниц, используемых ссылок и время пребывания Пользователя на
            той или иной странице). Постоянные cookie-файлы Сайт распознает постоянные cookie, которые хранятся на жестких дисках компьютеров Пользователей Сайта, и, присваивая устройствам
            Пользователей Сайта уникальные идентификаторы, Администрация Сайта может создать базу данных о действиях и предпочтениях Пользователей Сайта (в частности, о частоте посещения и
            периодичности возвращения Пользователей Сайта, об их предпочтениях на Сайте). Важно, что cookie-файлы не содержат личных данных Пользователей Сайта, они только фиксируют их действия.
            Согласие на использование cookie Сеансовые cookie-файлы не требуют предварительного согласия Пользователей Сайта; постоянные cookie-файлы такого согласия требуют. Управлять cookie-файлами
            Пользователи Сайта могут через настройки веб-браузера. Таким образом, у них есть возможность разрешить использование всех cookie-файлов, интегрированных в страницы или заблокировать
            cookie-файлы, а также включить использование cookie-файлов по запросу, принимать или отклонять cookie-файлы. Управление cookie-файлами различается в зависимости от используемого браузера.
            При этом применяя технологию cookie, Администрация Сайта не хранит и не использует никакие конкретные данные о Пользователях Сайта. Администрация Сайта обращает внимание, что Пользователь
            Сайта вправе настроить браузер на отказ от регистрации обращений к Сайту или на предупреждения о запросах на подобную регистрацию. Отключение «cookie» может повлечь невозможность доступа к
            Сайту.
          </p>

          <p>
            2.13. Если Администрация Сайта не сможет никаким образом соотнести информацию, указанную в пунктах 2.11-2.12 Политики конфиденциальности с Пользователем Сайта (физическим лицом),
            Администрация Сайта не будет считать данную информацию персональными данными.
          </p>
          <p className={cls.col}>3. Цели обработки персональных данных Пользователей Сайта</p>
          <p>
            3.1. Сайт собирает и хранит только те персональные данные Пользователя Сайта, которые необходимы для предоставления сервисов или исполнения соглашений и договоров с Пользователем Сайта, за
            исключением случаев, когда законодательством предусмотрено обязательное хранение персональных данных в течение определенного законом срока.{' '}
          </p>
          <p>3.2. Персональные данные Пользователя Сайт обрабатывает в следующих целях: </p>
          <p>3.2.1. Предоставления Пользователям Сайта регистрации (авторизации) на Сайте, доступа к Сайту и любых других случаях, связанных с такими действиями. </p>
          <p className={cls.col}>3.2.2. Предоставления Пользователю Сайта доступа к персонализированным ресурсам Сайта. </p>
          <p>
            3.2.3. Установления с Пользователем Сайта обратной связи, включая направление уведомлений, запросов, касающихся использования Сайта, оказания услуг, обработку запросов и заявок от
            Пользователя Сайта.
          </p>
          <p>3.2.4. Создания учетной записи, если Пользователь Сайта дал согласие на создание учетной записи. </p>
          <p>3.2.5. Предоставления Пользователю Сайта эффективной клиентской и технической поддержки при возникновении проблем, связанных с использованием Сайта.</p>
          <p>
            3.2.6. Обеспечения работоспособности и безопасности Сайта, для подтверждения совершаемых Пользователями Сайта действий, для предотвращения случаев мошенничества, компьютерных атак и иных
            злоупотреблений, а также для расследования таких случаев.
          </p>
          <p>4. Условия обработки персональных данных Пользователей Сайтаи передача их третьим лицам </p>
          <p>4.1. Сайт хранит персональные данные Пользователей Сайта в соответствии с внутренними регламентами конкретных сервисов. </p>
          <p className={cls.col}>
            4.2. В отношении персональных данных Пользователя Сайта сохраняется их конфиденциальность, кроме случаев добровольного предоставления Пользователем Сайта данных о себе для общего доступа
            неограниченному кругу лиц. При использовании отдельных сервисов Пользователь Сайта соглашается с тем, что определенная часть его персональных данных становится общедоступной.{' '}
          </p>
          <p>4.3. Сайт вправе передать персональные данные Пользователя Сайта третьим лицам в следующих случаях:</p>
          <p>4.3.1. Пользователь Сайта выразил согласие на такие действия. </p>
          <p>4.3.2. Передача необходима для использования Пользователем Сайта определенного сервиса либо для исполнения определенного соглашения или договора с Пользователем Сайта.</p>
          <p>4.3.3. Передача предусмотрена законодательством Российской Федерации в рамках установленной законодательством процедуры.</p>
          <p className={cls.col}>
            4.4. Настоящим Администрация Сайта уведомляет Пользователя Сайта, что в том случае, если Пользователь Сайта пожелал внести уточнения в его персональные данные, заблокировать или уничтожить
            их в случае, если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки, либо в случае
            возникновения у Пользователя желания отозвать свое согласие на обработку персональных данных или устранить неправомерные действия Администрации Сайта в отношении персональных данных
            Пользователя Сайта, то Пользователь Сайта должен направить Администрации Сайта официальный запрос по адресу: 125373, г. Москва, Походный проезд, домовладение 3, строение 1, в качестве
            получателя необходимо указывать: АО «ГНИВЦ».
          </p>
        </div>
      </div>
      <NewFooter />
    </div>
  );
}
