import get from 'lodash/get';
import moment from 'moment';

import * as Types from './types';

export const textureTypes: string[] = ['albedoMap', 'normalMap', 'roughnessMap', 'metallicMap'];

export const textureParamTypes: string[] = ['scale', 'offset', 'rotation'];

export const Product = (item: any): Types.IEntity.Product => ({
    id: get(item, 'id'),
    createdat: {
      value: get(item, 'createdat'),
      label: moment(get(item, 'createdat')).format('MMMM DD, YYYY')
    },
    updatedat: {
      value: get(item, 'updateat'),
      label: moment(get(item, 'updateat')).format('MMMM DD, YYYY')
    },
    description: get(item, 'description') || '',
    sku: get(item, 'sku') || '',
    brand: get(item, 'sku') || '',
    alias: get(item, 'alias') || '',
    ispublic: get(item, 'ispublic') || false,
    title: get(item, 'title') || '',
    name: get(item, 'name') || '',
    gender: get(item, 'gender') || '',
    category: get(item, 'category') || '',
    subcategory: get(item, 'subcategory') || '',
    materials: get(item, 'materials') || [],
    options: get(item, 'options') || [],
    fabrics: get(item, 'fabrics') || [],
    images: get(item, 'images') || [],
    variants: get(item, 'variants') || [],
    silhouette: get(item, 'silhouette') || { front: '', back: '' }
  });
