import React from 'react';
import { useTranslation } from 'react-i18next';

// @ts-ignore
import mission from 'assets/video/mission.mp4';

import cls from './mission.module.scss';

export default function Mission() {
  const { t } = useTranslation();

  return (
    <div className={cls.wrapper}>
      <div className={cls.container}>
        <div className={cls.left}>
          <div
            className={cls['left-video']}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: `
              <video loop playsInline muted autoPlay className={cls.video}>
              <source src=${mission} type="video/mp4" />
            </video>,
      `
            }}
          />
        </div>
        <div className={cls.right}>
          <h1 className={cls.title}>{t('mission.title')}</h1>
          {/* @ts-ignore */}
          <p className={cls.description} dangerouslySetInnerHTML={{ __html: t('mission.description') }} />
        </div>
      </div>
    </div>
  );
}
